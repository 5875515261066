import { ref, computed } from 'vue'
import { gql } from '@apollo/client'
import {
  ammGetIndexPrice,
  ammGetEstimatedFunding,
  ammGetMaxPosition,
  ammGetTransactionFee,
  ammGetAddress,
  ammGetIsOverFluctuationLimit,
  ammGet1hFunding
} from '../contracts/amm'
import { getIsArbitrager } from '@/contracts/clearingHouse'
import { getAmmContractAddress } from '../contracts/contracts'
import { formatAmm } from '../utils/utils'
import { wssClient } from '../api/graph'
import { AMM_SUBSCRIPTION } from '../api/queries'

const maxPositions = {}
const ammRef = ref(null)
const subscriptionRef = ref(null)

export const subscribeAmm = (ammSymbol) => {
  if (subscriptionRef.value) {
    subscriptionRef.value.unsubscribe()
  }

  subscriptionRef.value = wssClient
    .subscribe({
      query: gql(AMM_SUBSCRIPTION),
      variables: {
        id: getAmmContractAddress(ammSymbol).toLowerCase()
      }
    })
    .subscribe({
      next(data) {
        ammRef.value = formatAmm(data.data.amm)
      },
      error(err) {
        console.error('err', err)
      }
    })
}

export const unsubscribeAmm = () => {
  if (subscriptionRef.value) {
    subscriptionRef.value.unsubscribe()
  }
  subscriptionRef.value = null
}

const getIndexPrice = async (symbol) => {
  return await ammGetIndexPrice(symbol)
}

const getEstimatedFunding = async (symbol) => {
  return await ammGetEstimatedFunding(symbol)
}

const get1hFunding = async (symbol) => {
  return await ammGet1hFunding(symbol)
}

const getIsOverFluctuationLimit = async (symbol, positionSize) => {
  return await ammGetIsOverFluctuationLimit(symbol, positionSize)
}

const getMaxPosition = async (symbol, account) => {
  if (!symbol || !account) {
    return 0
  }

  if (await getIsArbitrager(account)) {
    // unlimited position size
    return Number.MAX_SAFE_INTEGER - 1
  }

  if (!maxPositions[symbol]) {
    maxPositions[symbol] = await ammGetMaxPosition(symbol)
  }
  return maxPositions[symbol]
}

const getTransactionFee = async () => {
  return await ammGetTransactionFee()
}

const getAddress = async (symbol) => {
  return await ammGetAddress(symbol)
}

export const useAMM = () => {
  const amm = computed(() => {
    return ammRef.value
  })

  const markPrice = computed(() => {
    if (ammRef.value) {
      if (ammRef.value.baseAssetReserve.eq(0)) {
        return null
      }
      return ammRef.value.quoteAssetReserve.div(ammRef.value.baseAssetReserve)
    } else {
      return null
    }
  })

  return {
    amm,
    markPrice,
    getIndexPrice,
    getEstimatedFunding,
    getMaxPosition,
    getTransactionFee,
    getAddress,
    getIsOverFluctuationLimit,
    get1hFunding
  }
}
