import Big from 'big.js';
import { utils } from 'ethers';
import { getMinutes } from 'date-fns';
import { clearingHouse, getAmmContract, referral } from './contracts';
import { bigNum2Big, DEFAULT_DECIMALS, ratioToPercentage } from './helpers';
import { PositionDirection as Dir } from '@/types/position';
import { constants } from 'ethers';
import { cacheGet, cacheSet } from '@/utils/utils';
export const ammGetIndexPrice = async (symbol) => {
    const cacheKey = `index_price_${symbol}`;
    const cachedValue = cacheGet(cacheKey);
    if (cachedValue) {
        return cachedValue;
    }
    let price;
    const amm = getAmmContract(symbol);
    try {
        const underlyingPrice = await amm.getUnderlyingPrice();
        const up = new Big(underlyingPrice.toString());
        price = up.div(10 ** 18).toString();
    }
    catch (e) {
        price = 0.0;
    }
    cacheSet(cacheKey, price, 1000);
    return price;
};
export const ammGetEstimatedFunding = async (symbol) => {
    const cacheKey = `estimated_funding_rate_${symbol}`;
    const cachedValue = cacheGet(cacheKey);
    if (cachedValue) {
        return cachedValue;
    }
    const amm = getAmmContract(symbol);
    const durationFromSharp = getMinutes(new Date()) * 60;
    const twapPrice = await amm.getTwapPrice(durationFromSharp);
    const underlyingTwapPrice = await amm.getUnderlyingTwapPrice(durationFromSharp);
    const fundingPeriod = await amm.fundingPeriod();
    const oneDayInSec = 60 * 60 * 24;
    const marketTwapPrice = new Big(twapPrice.toString()).div(10 ** 18);
    const indexTwapPrice = new Big(underlyingTwapPrice.toString()).div(10 ** 18);
    const premium = marketTwapPrice.minus(indexTwapPrice);
    const premiumFraction = premium.mul(fundingPeriod.toString()).div(oneDayInSec);
    const estimatedFunding = premiumFraction.div(indexTwapPrice);
    const funding = (estimatedFunding.toNumber() * 100).toFixed(4);
    cacheSet(cacheKey, funding);
    return funding;
};
export const ammGet1hFunding = async (symbol) => {
    const cacheKey = `funding_rate_${symbol}`;
    const cachedValue = cacheGet(cacheKey);
    if (cachedValue) {
        return cachedValue;
    }
    const amm = getAmmContract(symbol);
    const durationFromSharp = getMinutes(new Date()) * 60;
    const twapPrice = await amm.getTwapPrice(durationFromSharp);
    const underlyingTwapPrice = await amm.getUnderlyingTwapPrice(durationFromSharp);
    const fundingPeriod = await amm.fundingPeriod();
    const oneHourInSec = 60 * 60;
    const marketTwapPrice = new Big(twapPrice.toString()).div(10 ** 18);
    const indexTwapPrice = new Big(underlyingTwapPrice.toString()).div(10 ** 18);
    const premium = marketTwapPrice.minus(indexTwapPrice);
    const premiumFraction = premium
        .mul(fundingPeriod.toString())
        .div(oneHourInSec);
    const estimatedFunding = premiumFraction.div(indexTwapPrice);
    const funding = (estimatedFunding.toNumber() * 100).toFixed(4);
    // cache value for 60 seconds
    cacheSet(cacheKey, funding);
    return funding;
};
export const ammGetMaxPosition = async (symbol) => {
    const amm = getAmmContract(symbol);
    const mp = await amm.getMaxHoldingBaseAsset();
    return new Big(mp.toString()).div(10 ** DEFAULT_DECIMALS).toNumber();
};
export const ammGetTransactionFee = async () => {
    return ratioToPercentage(await clearingHouse.feeRatio());
};
export const ammGetSponsor = async (wallet) => {
    const sponsor = await referral.sponsor(wallet);
    return sponsor === constants.AddressZero ? null : sponsor;
};
export const calcFee = async (ammSymbol, quoteAssetAmount) => {
    const amm = getAmmContract(ammSymbol);
    return await amm.calcFee({
        d: utils.parseUnits(quoteAssetAmount.toString(), DEFAULT_DECIMALS)
    });
};
export const getSpotPrice = async (ammSymbol) => {
    const cacheKey = `spot_price_${ammSymbol}`;
    const cachedValue = cacheGet(cacheKey);
    if (cachedValue) {
        return cachedValue;
    }
    const amm = getAmmContract(ammSymbol);
    const price = await amm.getSpotPrice();
    cacheSet(cacheKey, price, 1000);
    return price;
};
export const getOutputPrice = async (ammSymbol, dir, baseAssetAmount) => {
    const amm = getAmmContract(ammSymbol);
    const ret = await amm.getOutputPrice(dir.toString(), {
        d: utils.parseUnits(baseAssetAmount.toString(), DEFAULT_DECIMALS)
    });
    return bigNum2Big(ret.d);
};
export const ammGetAddress = async (ammSymbol) => {
    const amm = getAmmContract(ammSymbol);
    return amm.address;
};
export const getInputPrice = async (ammSymbol, dir, quoteAssetAmount) => {
    const amm = getAmmContract(ammSymbol);
    const ret = await amm.getInputPrice(dir.toString(), {
        d: utils.parseUnits(quoteAssetAmount.round(DEFAULT_DECIMALS).toString(), DEFAULT_DECIMALS)
    });
    return bigNum2Big(ret.d);
};
export const ammGetIsOverFluctuationLimit = async (symbol, positionSize) => {
    const amm = getAmmContract(symbol);
    try {
        const dir = positionSize.gte(0) ? Dir.ADD_TO_AMM : Dir.REMOVE_FROM_AMM;
        const isOverFluctuationLimit = await amm.isOverFluctuationLimit(dir, {
            d: utils.parseUnits(positionSize.abs().toString(), DEFAULT_DECIMALS)
        });
        return isOverFluctuationLimit;
    }
    catch (e) {
        console.error('ammGetIsOverFluctuationLimit:', e);
        return false;
    }
};
