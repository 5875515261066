import {
  setSignedContracts,
  getSignedContracts,
  farmingStaking,
  palmToken,
  palmBNBLP,
  palmUSDTLP
} from '@/contracts/contracts'

export async function approveToken(
  tokenId,
  spender,
  amount,
  signer,
  progressCallback
) {
  setSignedContracts(signer)
  const { palmTokenSigned, palmBNBLPSined, palmUSDTLPSined } =
    getSignedContracts()
  let tokenSigned
  switch (tokenId) {
    case 'palmToken':
      tokenSigned = palmTokenSigned
      break
    case 'palmBNBLP':
      tokenSigned = palmBNBLPSined
      break
    case 'palmUSDTLP':
      tokenSigned = palmUSDTLPSined
      break
  }
  if (progressCallback) {
    progressCallback()
  }
  const tx = await tokenSigned.approve(spender, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function getTokenAllowance(tokenId, owner, spender) {
  let tokenContract
  switch (tokenId) {
    case 'palmToken':
      tokenContract = palmToken
      break
    case 'palmBNBLP':
      tokenContract = palmBNBLP
      break
    case 'palmUSDTLP':
      tokenContract = palmUSDTLP
      break
  }
  const allowance = await tokenContract.allowance(owner, spender)

  return allowance
}

export async function depositStake(token, amount, signer, progressCallback) {
  setSignedContracts(signer)
  const { farmingStakingSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await farmingStakingSigned.deposit(token, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function withdrawStake(token, amount, signer, progressCallback) {
  setSignedContracts(signer)
  const { farmingStakingSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await farmingStakingSigned.withdraw(token, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function claimStake(token, amount, signer, progressCallback) {
  setSignedContracts(signer)
  const { farmingStakingSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await farmingStakingSigned.claim(token, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function compoundStake(token, amount, signer, progressCallback) {
  setSignedContracts(signer)
  const { farmingStakingSigned } = getSignedContracts()
  if (progressCallback) {
    progressCallback()
  }
  const tx = await farmingStakingSigned.compound(token, amount)
  const receipt = await tx.wait()
  return receipt
}

export async function getUserInfo(tokenAddress, userAddress) {
  const userInfo = await farmingStaking.userInfo(tokenAddress, userAddress)
  return userInfo
}

export async function getPendingAmount(tokenAddress, userAddress) {
  return await farmingStaking.getPendingAmount(tokenAddress, userAddress)
}
