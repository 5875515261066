import { Web3Provider } from '@ethersproject/providers';
import { init, useOnboard } from '@web3-onboard/vue';
import { ProviderLabel } from '@web3-onboard/injected-wallets/dist/types';
import { getSmartWallet } from '../../contracts';
import { switchToCurrentNetwork } from '@/contracts/helpers';
import { getTokenWithExpiry, setTokenWithExpiry } from '@/helpers/auth';
import { markRaw } from 'vue';
import { palmBNBLP } from '@/contracts/contracts';
import useBreakpoints from '@/hooks/useBreakpoints';
import palmLogoSymbol from '@/assets/logo-new-symbol.svg';
import palmLogo from '@/assets/logo-new.svg';
import { hexlify } from '@ethersproject/bytes';
import * as constants from '@ethersproject/constants';
import { walletWithWalletConnectWorkaroundModule } from '@/helpers/web3-onboard';
const web3ModalStore = {
    state: {
        web3Modal: null,
        provider: null,
        connected: false,
        account: null,
        authToken: null,
        chainId: 0,
        networkId: 0,
        label: null
    },
    mutations: {
        setWeb3Modal(state, web3Modal) {
            state.web3Modal = web3Modal;
        },
        setProvider(state, provider) {
            state.provider = markRaw(provider);
        },
        setConnected(state, connected) {
            state.connected = connected;
        },
        setAuthToken(state, token) {
            state.authToken = token;
        },
        setChainId(state, chainId) {
            state.chainId = chainId;
        },
        setNetworkId(state, networkId) {
            state.networkId = networkId;
        },
        setLabel(state, label) {
            state.label = label;
        }
    },
    actions: {
        async initialize({ commit, dispatch }) {
            const [mewWalletModule, walletConnectModule, coinbaseWalletModule, walletsInjected] = await Promise.all([
                import(
                /* webpackChunkName: "web3-onboard" */
                '@web3-onboard/mew-wallet').then((m) => m.default),
                import(
                /* webpackChunkName: "web3-onboard" */
                '@web3-onboard/walletconnect').then((m) => m.default),
                import(
                /* webpackChunkName: "web3-onboard" */
                '@web3-onboard/coinbase').then((m) => m.default),
                import(
                /* webpackChunkName: "web3-onboard" */
                '@web3-onboard/injected-wallets/dist/wallets').then((m) => m.default)
            ]);
            /**
             * Mew
             */
            const mewWallet = mewWalletModule();
            /**
             * Wallet connect
             */
            const walletConnectWallet = walletConnectModule({
                qrcodeModalOptions: {
                    mobileLinks: [
                        'rainbow',
                        'metamask',
                        'argent',
                        'trust',
                        'imtoken',
                        'pillar'
                    ]
                },
                // TODO: migrate to 2 version: https://github.com/blocknative/web3-onboard
                version: 1
            });
            /**
             * Coinbase
             */
            const coinbaseWallet = coinbaseWalletModule();
            /**
             * Metamask
             */
            const metaMaskWallet = walletWithWalletConnectWorkaroundModule({
                label: ProviderLabel.MetaMask,
                isInterfaceAvailable: () => {
                    return window.ethereum && window.ethereum.isMetaMask;
                },
                openInMobile: (location) => {
                    const formattedAppUrl = location.href.replace(/^\w+:\/\//, '');
                    window.open(`https://metamask.app.link/dapp/${formattedAppUrl}`, '_blank');
                },
                walletsInjected,
                walletConnectModule
            });
            /**
             * Trust wallet
             */
            const trustWallet = walletWithWalletConnectWorkaroundModule({
                label: ProviderLabel.Trust,
                isInterfaceAvailable: () => {
                    return window.ethereum && window.ethereum.isTrust;
                },
                openInMobile: (location) => {
                    // https://github.com/satoshilabs/slips/blob/master/slip-0044.md
                    const bscNetworkId = 9006;
                    const formattedAppUrl = location.href.replace(/^\w+:\/\//, '');
                    // https://developer.trustwallet.com/developer/develop-for-trust/deeplinking#dapp-browser
                    window.open(`https://link.trustwallet.com/open_url?coin_id=${bscNetworkId}&url=${formattedAppUrl}`, '_blank');
                },
                walletsInjected,
                walletConnectModule
            });
            const appMetadata = {
                name: 'Palmswap',
                icon: palmLogoSymbol,
                logo: palmLogo,
                description: 'Trade perpetuals decentralized with up to 10x leverage and earn tokens while trading through Tradingcycles.',
                gettingStartedGuide: 'https://v1.palmswap.org/get-started'
            };
            const wallets = [
                metaMaskWallet,
                trustWallet,
                walletConnectWallet,
                coinbaseWallet,
                mewWallet
            ].filter(Boolean);
            const rpcUrl = process.env.VUE_APP_RPC_PROVIDER_URL;
            const web3Onboard = init({
                appMetadata,
                wallets,
                accountCenter: {
                    desktop: { enabled: false },
                    mobile: { enabled: false }
                },
                chains: [
                    {
                        id: process.env.VUE_APP_NETWORK_ID,
                        token: 'BSC',
                        label: 'Binance Smart Chain',
                        rpcUrl
                    }
                ]
            });
            const { alreadyConnectedWallets } = useOnboard();
            if (alreadyConnectedWallets.value.length > 0) {
                dispatch('connect');
            }
            commit('setWeb3Modal', web3Onboard);
        },
        async connect({ commit, dispatch }) {
            try {
                const { isMobile } = useBreakpoints();
                const { connectWallet, connectedWallet, alreadyConnectedWallets } = useOnboard();
                let options = null;
                if (alreadyConnectedWallets.value.length > 0) {
                    options = {
                        autoSelect: {
                            label: alreadyConnectedWallets.value[0],
                            disableModals: true
                        }
                    };
                }
                await connectWallet(options);
                if (!connectedWallet.value) {
                    return;
                }
                const provider = connectedWallet.value.provider;
                const label = connectedWallet.value.label;
                let library = new Web3Provider(provider);
                let network = await library.getNetwork();
                commit('setProvider', library);
                commit('setNetworkId', network.name);
                commit('setChainId', network.chainId);
                commit('setConnected', true);
                commit('setLabel', label);
                await dispatch('signIn');
                const networkId = hexlify(network.chainId);
                if (networkId !== process.env.VUE_APP_NETWORK_ID && !isMobile.value) {
                    await switchToCurrentNetwork(provider);
                    library = new Web3Provider(provider);
                    network = await library.getNetwork();
                    commit('setProvider', library);
                    commit('setNetworkId', network.name);
                    commit('setChainId', network.chainId);
                }
                const accounts = await library.listAccounts();
                if (accounts.length > 0) {
                    commit('setAccountAddress', accounts[0]);
                    dispatch('updateLastConnectedAt', new Date());
                    try {
                        let smartWallet = await getSmartWallet(library.getSigner());
                        if (smartWallet !== constants.AddressZero) {
                            commit('setWalletAddress', smartWallet);
                        }
                    }
                    catch (e) {
                        console.error(e);
                    }
                }
                await dispatch('updateBalance');
                dispatch('setTACVisible');
                await dispatch('signIn');
                palmBNBLP.on('Transfer', async (from, to) => {
                    if (to == accounts[0] || from == accounts[0]) {
                        await dispatch('updateBalance');
                    }
                });
                provider.on('accountsChanged', async (accounts) => {
                    if (accounts.length > 0) {
                        commit('setAccountAddress', accounts[0]);
                        let smartWallet = await getSmartWallet(library.getSigner());
                        if (smartWallet !== constants.AddressZero) {
                            commit('setWalletAddress', smartWallet);
                        }
                        else {
                            commit('setWalletAddress', '');
                        }
                        await dispatch('updateBalance');
                        dispatch('setTACVisible');
                        await dispatch('signIn');
                    }
                    else {
                        await dispatch('resetApp');
                        await dispatch('resetUserData');
                    }
                });
                provider.on('chainChanged', async (chainId) => {
                    chainId = parseInt(chainId);
                    commit('setChainId', chainId);
                    await dispatch('updateBalance');
                });
            }
            catch (error) {
                console.error(error);
            }
        },
        async resetApp({ commit, dispatch }) {
            try {
                const { disconnectConnectedWallet } = useOnboard();
                await disconnectConnectedWallet();
            }
            catch (error) {
                console.error(error);
            }
            commit('setConnected', false);
            commit('setProvider', {});
            commit('setAuthToken', null);
            await dispatch('resetUserData');
        },
        async changeNetwork({ commit }) {
            try {
                const { connectedWallet } = useOnboard();
                if (!connectedWallet.value) {
                    return;
                }
                const provider = connectedWallet.value.provider;
                let library = new Web3Provider(provider);
                let network = await library.getNetwork();
                await switchToCurrentNetwork(provider);
                library = new Web3Provider(provider);
                network = await library.getNetwork();
                commit('setProvider', library);
                commit('setNetworkId', network.name);
                commit('setChainId', network.chainId);
            }
            catch (error) {
                console.error(error);
            }
        },
        async signIn({ state, commit }) {
            const provider = state.provider;
            const signer = provider.getSigner();
            const address = await signer.getAddress();
            try {
                let token = getTokenWithExpiry(address);
                if (!token) {
                    setTokenWithExpiry(address, null);
                }
                else {
                    commit('setAuthToken', token);
                }
            }
            catch (e) {
                setTokenWithExpiry(address, null);
                commit('setAuthToken', null);
                console.error(e);
            }
        }
    }
};
export default web3ModalStore;
