<template>
  <PNavDropdown
    name="More"
    :onOpen="() => toggleBlur(true)"
    :onClose="() => toggleBlur(false)"
    :class="{ 'z-20': blurShow }"
  >
    <template v-slot:main>
      <div class="flex gap-2 w-max">
        <div class="flex flex-col gap-2 w-max">
          <a
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
            href="https://v1.palmswap.org/"
          >
            <DocumentIcon class="h-4" />
            <div>Documentation</div>
          </a>
          <a
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
            href="https://palmswap.org/referral_agreement"
          >
            <DocumentCheckIcon class="h-4" />
            <div>Referral Agreement</div>
          </a>
          <a
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
            href="https://palmswap.org/terms_of_use"
          >
            <DocumentTextIcon class="h-4" />
            <div>Terms of Service</div>
          </a>
          <a
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
            href="https://palmswap.org/privacy"
          >
            <ShieldExclamationIcon class="h-4" />
            <div>Privacy Policy</div>
          </a>
          <a
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
            href="https://angel.co/company/palmswap-1/jobs"
          >
            <BriefcaseIcon class="h-4" />
            <div>Career</div>
          </a>
          <a
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
            href="https://palmswap.canny.io/"
          >
            <ChatBubbleBottomCenterTextIcon class="h-4" />
            <div>Feedback</div>
          </a>
        </div>
        <div class="flex flex-col gap-2 w-40">
          <a
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
            href="https://blog.palmswap.org/"
          >
            <NewspaperIcon class="h-4" />
            <div>Blog</div>
          </a>
          <a
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
            href="https://linktr.ee/palmswap"
          >
            <CursorArrowRaysIcon class="h-4" />
            <div>Social Media</div>
          </a>
          <a
            class="flex gap-1 items-center cursor-pointer px-3 py-2 rounded-lg hover:bg-background-2"
            href=""
          >
            <ChartBarIcon class="h-4" />
            <div>Status</div>
          </a>
        </div>
      </div>
    </template>
  </PNavDropdown>
  <Transition name="fade">
    <div
      v-show="blurShow"
      class="fixed -inset-2 z-10 flex justify-center items-center bg-black bg-opacity-50 backdrop-filter backdrop-blur-sm"
    />
  </Transition>
</template>

<script>
import {
  DocumentTextIcon,
  DocumentIcon,
  ShieldExclamationIcon,
  BriefcaseIcon,
  NewspaperIcon,
  CursorArrowRaysIcon,
  ChatBubbleBottomCenterTextIcon,
  DocumentCheckIcon
} from '@heroicons/vue/20/solid'
import { ChartBarIcon } from '@heroicons/vue/20/solid'
import { PNavDropdown } from '../../../palmswap-vue-ui/dist/palmswap-ui.es'
export default {
  components: {
    DocumentIcon,
    DocumentTextIcon,
    ShieldExclamationIcon,
    ChatBubbleBottomCenterTextIcon,
    BriefcaseIcon,
    NewspaperIcon,
    CursorArrowRaysIcon,
    ChartBarIcon,
    DocumentCheckIcon,
    PNavDropdown
  },
  data() {
    return {
      blurShow: false
    }
  },
  methods: {
    toggleBlur(blur) {
      this.blurShow = blur
    }
  }
}
</script>
