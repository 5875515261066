import metadata from './metadata'
import { provider } from './providers'
import { BigNumber, Contract } from 'ethers'

//APEX
import SmartWalletAbi from './abis/smart-wallet.json'
import SmartWalletFactoryAbi from './abis/smart-wallet-factory.json'
import LimitOrderBookAbi from './abis/limit-order-book.json'
import AmmAbi from './abis/amm.json'
import PalmPad from './abis/palmPad.json'
import Referrals from './abis/referral.json'
import AmmReaderAbi from './abis/ammReader.json'
import CHViewerAbi from './abis/CHViewer.json'
import ClearingHouseAbi from './abis/clearingHouse.json'
import ERC20Abi from './abis/erc20.json'
import PriceAggregator from './abis/priceAggregator.json'
import FarmingStakingAbi from './abis/farmingStaking.json'
import PalmTokenAbi from './abis/palmToken.json'
import pancakePairAbi from './abis/pancakePair.json'
import vestingAbi from './abis/vesting.json'
import feeDistributorAbi from './abis/feeDistributor.json'
import faucetAbi from './abis/faucet.json'

const usdtAddr = metadata.usdt
const palmBNBAddr = metadata.palmBNBLP
const palmUSDTAddr = metadata.palmUSDTLP

export const clearingHouseAddr = metadata.clearingHouse
const ammReaderAddr = metadata.ammReader
const referralAddr = metadata.referral
const palmPadAddr = metadata.palmPad
export const palmTokenAddr = metadata.palmToken
const palmVestingAddr = metadata.vesting
const farmingStakingAddr = metadata.farmingStaking
const chViewerAddr = metadata.clearingHouseViewer
const feeDistributorAddr = metadata.feeDistributor

const faucetAddr = metadata.faucet

// apex contracts
const smartWalletFactoryAddress = metadata.smartWalletFactory
const limitOrderBookAddr = metadata.limitOrderBook

// read only contracts
export const clearingHouse = new Contract(
  clearingHouseAddr,
  ClearingHouseAbi,
  provider
)

export const clearingHouseViewer = new Contract(
  chViewerAddr,
  CHViewerAbi,
  provider
)

export const usdt = new Contract(usdtAddr, ERC20Abi, provider)
export const palmBNBLP = new Contract(palmBNBAddr, pancakePairAbi, provider)
// USDT LP wasn't deployed on mainnet, so we mock the data in case the SC address isn't provided in metadata.
export const palmUSDTLP = palmUSDTAddr
  ? new Contract(palmUSDTAddr, pancakePairAbi, provider)
  : {
      allowance: () => Promise.resolve(BigNumber.from(0)),
      balanceOf: () => Promise.resolve(BigNumber.from(0))
    }
export const palmToken = new Contract(palmTokenAddr, ERC20Abi, provider)
export const palmVesting = new Contract(palmVestingAddr, vestingAbi, provider)
export const ammReader = new Contract(ammReaderAddr, AmmReaderAbi, provider)
export const palmPad = new Contract(palmPadAddr, PalmPad, provider)
export const referral = new Contract(referralAddr, Referrals, provider)
export const farmingStaking = new Contract(
  farmingStakingAddr,
  FarmingStakingAbi,
  provider
)
export const feeDistributor = new Contract(
  feeDistributorAddr,
  feeDistributorAbi,
  provider
)
export const faucet = new Contract(faucetAddr, faucetAbi, provider)

// read and write contracts
let usdtSigned = null
let clearingHouseSigned = null
let smartWalletFactorySigned = null
let farmingStakingSigned = null
let palmTokenSigned = null
let palmBNBLPSigned = null
let palmUSDTLPSigned = null
let limitOrderBookSigned = null

export const setSignedContracts = (signer) => {
  usdtSigned = new Contract(usdtAddr, ERC20Abi, signer)
  clearingHouseSigned = new Contract(
    clearingHouseAddr,
    ClearingHouseAbi,
    signer
  )
  smartWalletFactorySigned = new Contract(
    smartWalletFactoryAddress,
    SmartWalletFactoryAbi,
    signer
  )
  limitOrderBookSigned = new Contract(
    limitOrderBookAddr,
    LimitOrderBookAbi,
    signer
  )
  palmUSDTLPSigned = new Contract(palmUSDTAddr, pancakePairAbi, signer)
  palmBNBLPSigned = new Contract(palmBNBAddr, pancakePairAbi, signer)
  palmTokenSigned = new Contract(palmTokenAddr, PalmTokenAbi, signer)
  farmingStakingSigned = new Contract(
    farmingStakingAddr,
    FarmingStakingAbi,
    signer
  )
}

export const getSignedContracts = () => {
  return {
    clearingHouseSigned,
    usdtSigned,
    smartWalletFactorySigned,
    limitOrderBookSigned,
    farmingStakingSigned,
    palmTokenSigned,
    palmBNBLPSigned,
    palmUSDTLPSigned
  }
}

/**
 *
 * @param {string} ammSymbol
 * @returns {string} address
 */
export const getAmmContractAddress = (ammSymbol) => {
  const ammContractAddress = metadata.amms[ammSymbol]
  if (!ammContractAddress) {
    throw `No contract for ${ammSymbol}`
  }
  return ammContractAddress
}
export const getSymbol = (contractAddress) => {
  const symbol = Object.keys(metadata.amms).find(
    (key) => metadata.amms[key].toLowerCase() === contractAddress
  )
  if (!symbol) {
    throw `No symbol for ${contractAddress}`
  }
  return symbol
}

export const getAmmContract = (ammSymbol) => {
  const ammContractAddress = getAmmContractAddress(ammSymbol)
  return new Contract(ammContractAddress, AmmAbi, provider)
}

export const getSmartWalletContract = (smartWallet, signer) => {
  return new Contract(smartWallet, SmartWalletAbi, signer)
}

export const getPriceFeedContract = (symbol) => {
  const address = metadata.priceFeeds[symbol]
  if (!address) {
    throw `No price feed for ${symbol}`
  }
  return new Contract(address, PriceAggregator, provider)
}

export async function getCurrentBlock() {
  const currentBlock = await provider.getBlockNumber()
  return currentBlock
}
