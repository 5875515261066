<template>
  <div
    v-if="isTradePage"
    class="w-full bg-[#7053d6] text-white flex relative z-10 top-0 items-center text-xs sm:text-sm justify-between px-5 overflow-hidden transition-height duration-500 ease-in-out"
    :class="{
      'h-[0px]': !isBannerVisible,
      'h-[60px] sm:h-[41px]': isBannerVisible
    }"
  >
    <div></div>
    <div class="flex justify-center items-center text-white gap-5">
      <span
        >Trading on V1 has been disabled. Please withdraw your funds. We'll be
        back soon with V2.</span
      >
    </div>
    <div
      class="cursor-pointer flex items-center justify-end"
      @click="handleClose"
    >
      <img
        src="@/assets/closebanner.svg"
        alt="Close"
        class="close-icon md:h-[14px] h-[20px]"
      />
    </div>
  </div>

  <div
    v-if="isStakePage"
    class="w-full bg-[#7053d6] text-white flex relative z-10 top-0 items-center text-xs sm:text-sm justify-between px-5 overflow-hidden transition-height duration-500 ease-in-out"
    :class="{
      'h-[0px]': !isBannerVisible,
      'h-[60px] sm:h-[41px]': isBannerVisible
    }"
  >
    <div></div>
    <div class="flex justify-center items-center text-whiten gap-3">
      <span class="flex gap-10"
        >Please withdraw your funds and migrate to V2:
      </span>
      <a
        href="https://docs.palmswap.org/ecosystem/staking/v1-to-v2-migration"
        class="underline"
      >
        Step by step guide
      </a>
    </div>
    <div
      class="cursor-pointer flex items-center justify-end"
      @click="handleClose"
    >
      <img
        src="@/assets/closebanner.svg"
        alt="Close"
        class="close-icon md:h-[14px] h-[20px]"
      />
    </div>
  </div>

  <div
    class="mx-3 h-14 my-0.5 py-2 flex items-center justify-between relative bg-background-1"
  >
    <div class="flex-none items-center mr-6">
      <div class="hidden sm:block">
        <PLogo type="symbol-beta" class="h-8" />
      </div>
      <div class="sm:hidden flex items-center">
        <PLogo type="symbol-beta" class="h-8" />
        <PLogo type="name" class="h-8" />
      </div>
    </div>
    <div class="flex-1 text-sm items-center space-x-2 hidden sm:flex">
      <AppTypeDropdown />
      <NavItem name="Trade" route="/" />
      <NavItem name="Portfolio" route="/portfolio/overview" />
      <NavItem
        v-if="isCompetitionEnabled"
        name="Leaderboard"
        route="/leaderboard"
      />
      <EarnDropdown />
      <NavItem v-if="isFaucetEnabled" name="Faucet" route="/faucet" />
      <NavItem
        v-if="isRewardsEnabled"
        name="Rewards"
        icon="🔥"
        route="/rewards"
      />
      <MoreDropdown />
    </div>
    <div class="flex space-x-2">
      <a
        class="sm:block hidden select-none text-inactive text-sm cursor-poiner mt-auto mb-auto mr-1"
        href="https://palmswap.canny.io/"
      >
        Feedback
      </a>
      <div class="flex flex-none items-center h-9 flex items-center">
        <ConnectWallet />
      </div>
      <button class="sm:hidden" @click="toggleSidebarDialog(true)">
        <Bars3BottomRightIcon class="w-8 text-white" />
      </button>
    </div>
    <transition name="fade">
      <SideBar v-if="isSidebarDialogOpen" />
    </transition>
  </div>
</template>

<script>
import { Bars3BottomRightIcon } from '@heroicons/vue/24/solid'
import ConnectWallet from './top-bar/ConnectWallet.vue'
import NavItem from '@/components/top-bar/NavItem.vue'
import SideBar from './side-bar/SideBar.vue'
import { PLogo } from '../../palmswap-vue-ui'
import { isFeatureEnabled } from '@/utils/utils'
import { FEATURE_FLAGS, NODE_ENV } from '@/config/constants'
import { useDialogs } from '@/hooks/useDialogs'
import AppTypeDropdown from '@/components/top-bar/AppTypeDropdown.vue'
import MoreDropdown from '@/components/top-bar/MoreDropdown.vue'
import EarnDropdown from '@/components/top-bar/EarnDropdown.vue'
import { ref } from 'vue'

export default {
  components: {
    Bars3BottomRightIcon,
    ConnectWallet,
    NavItem,
    PLogo,
    SideBar,
    AppTypeDropdown,
    MoreDropdown,
    EarnDropdown
  },

  computed: {
    isTradePage() {
      return this.$route.path === '/trade/ETH-USDT'
    },
    isStakePage() {
      return this.$route.path === '/earn/staking'
    }
  },

  setup() {
    const { isSidebarDialogOpen, toggleSidebarDialog } = useDialogs()
    const isStakingEnabled = isFeatureEnabled(FEATURE_FLAGS.STAKING)
    const isFarmEnabled = isFeatureEnabled(FEATURE_FLAGS.FARM)
    const isLaunchpadEnabled = isFeatureEnabled(FEATURE_FLAGS.LAUNCHPAD)
    const isRewardsEnabled = isFeatureEnabled(FEATURE_FLAGS.TRADING_REWARDS)
    const isVestingEnabled = isFeatureEnabled(FEATURE_FLAGS.VESTING)
    const isFaucetEnabled = isFeatureEnabled(FEATURE_FLAGS.FAUCET)
    const isCompetitionEnabled = isFeatureEnabled(FEATURE_FLAGS.COMPETITION)
    const isTestnet = NODE_ENV === 'testnet'

    const isBannerVisible = ref(true)

    const handleClose = () => {
      isBannerVisible.value = false
    }

    return {
      isStakingEnabled,
      isFarmEnabled,
      isLaunchpadEnabled,
      isRewardsEnabled,
      isTestnet,
      isVestingEnabled,
      isFaucetEnabled,
      isSidebarDialogOpen,
      toggleSidebarDialog,
      isCompetitionEnabled,
      isBannerVisible,
      handleClose
    }
  }
}
</script>
